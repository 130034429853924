<section class="facsec2">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 [class]='styleTitle'*ngIf='!title'>Seguinos</h2>
                <h2 [class]='styleTitle' *ngIf='title'>{{title}}</h2>
                <div class="redes-sec">
                    <a href="https://es-la.facebook.com/universidad.catolica.argentina/" target="_blank"><img onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/facebook2.jpg" /></a>
                    <a href="https://www.youtube.com/user/UCAarg" target="_blank"><img onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/youtube2.jpg" /></a>
                    <a href="https://www.instagram.com/univcatolicaargentina/?hl=es" target="_blank"><img onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/instagram2.jpg" /></a>
                    <a href="https://www.linkedin.com/school/pontificia-universidad-cat%C3%B3lica-argentina/" target="_blank"><img onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/linkedin2.jpg" /></a>
                    <a href="https://x.com/UCA_Oficial" target="_blank"><img onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/x2.jpg" /></a>
                    <a href="https://www.tiktok.com/@ucaarg" target="_blank"><img onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/tiktok2.jpg" /></a>

                    <!--<a href="" target="_blank"><img onerror="this.src='./assets/img/default.png'" src="../../../../../assets/img/twitter3.jpg" /></a>-->
                
                </div>
            </div>
        </div>
        <div class="comu-cont desk">
            <div class="comu-item" *ngFor="let item of ListaSeguinos">
                <a [href]="item.link">

                    <img onerror="this.src='./assets/img/default.png'" [src]="item.img" />
                    <div class="comu-desc">
                        <div class="comu-tit"><img onerror="this.src='./assets/img/default.png'" [src]="item.redSocialIcon" /> Leer Más </div>
                        <p>{{item.titulo| slice:0:200}} ...</p>
                        <!--<p class="fecha">09 Mayo 2021</p>-->
                    </div>
                </a>
            </div>

        </div>
        <div class="comu-cont mob">
            <div id="comu-slider" class="carousel slide mob grid-cont t-dots" data-ride="carousel">
                <ol class="carousel-indicators">
                    <li *ngFor='let item of ListaSeguinos;index as i ' data-target="Noticias-s"
                        (click)="cambiarSlider(i)" [ngClass]='(item.id== Active)?"active" : ""'></li>

                </ol>
                <div class="carousel-inner">
                    {{Active}}
                    <div class="carousel-item " *ngFor="let item of ListaSeguinos"
                        [ngClass]='(item.id== Active)?"active" : ""'>
                        <div class="comu-item">
                            <a [href]="item.link">
                                <img onerror="this.src='./assets/img/default.png'" [src]="item.img" />
                                <div class="comu-desc">

                                    <div class="comu-tit"><img onerror="this.src='./assets/img/default.png'" [src]="item.redSocialIcon" /> Leer Más </div>
                                    <p>{{item.titulo}} ...</p>
                                    <!--<p class="fecha">09 Mayo 2021</p>-->
                                </div>
                            </a>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>